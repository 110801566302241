<template>
    <div class="order-page-header my-5 empty-d-none w-100">
        <router-link v-if="!$root.filterWithUrl && showUserBackButton && userID > 0" to="/ecommerce/user" class="btn btn-primary align-self-center float-start">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.ecommerce.user.title") }}
        </router-link>
        <div class="float-end" v-if="table.data.length">
            <el-tooltip :content="$t('common.exportTooltip')" popper-class="max-w-300px" placement="bottom">
                <button class="btn btn-danger align-self-center ms-3" :data-kt-indicator="exportLoading ? 'on' : null" :disabled="exportLoading" v-on:click="exportOrder">
                <span v-if="!exportLoading" class="indicator-label">
                    <span class="svg-icon svg-icon-1">
                        <inline-svg src="/media/icons/duotune/files/fil021.svg" />
                    </span>
                    {{ $t("common.export") }}
                </span>

                    <span v-if="exportLoading" class="indicator-progress">
                    <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                    {{ $t("messages.wait") }}
                </span>
                </button>
            </el-tooltip>
        </div>
    </div>
    <custom-table
        :title="$t('pages.ecommerce.order.title')"
        :subTitle="$t('pages.ecommerce.order.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="EcommerceOrderFilter"
        changeUrlPage
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:userName="{ row: record }">
            {{ record.user.firstname + ' ' + record.user.lastname }}
        </template>
        <template v-slot:userEmail="{ row: record }">
            <a :href="'mailto:' + record.user.email" class="text-gray-600 text-hover-primary mb-1">
                {{ record.user.email }}
            </a>
        </template>
        <template v-slot:orderTotal="{ row: record }">
            {{ record.total }} {{ record.currency.suffix }}
        </template>
        <template v-slot:paidTotal="{ row: record }">
            {{ record.payment_detail ? (record.payment_detail.paid_total + ' ' + record.currency.suffix) : "-" }}
        </template>
        <template v-slot:paymentMethod="{ row: record }">
            {{ record.payment_detail ? record.payment_detail.payment_method.name : "-" }}
        </template>
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <router-link :to="'/ecommerce/order-management/order/detail/' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                    </span>
                </router-link>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/custom-table";
export default {
    name: "index",
    props: ['userID', 'showUserBackButton'],
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.order.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.order.cols.userName"),
                    scopedSlots: {customRender: "userName"},
                },
                {
                    name: this.$t("pages.ecommerce.order.cols.userEmail"),
                    scopedSlots: {customRender: "userEmail"}
                },
                {
                    name: this.$t("pages.ecommerce.order.cols.orderTotal"),
                    scopedSlots: {customRender: "orderTotal"},
                },
                {
                    name: this.$t("pages.ecommerce.order.cols.paidTotal"),
                    scopedSlots: {customRender: "paidTotal"},
                },
                {
                    name: this.$t("pages.ecommerce.order.cols.paymentMethod"),
                    scopedSlots: {customRender: "paymentMethod"}
                },
                {
                    name: this.$t("pages.ecommerce.order.cols.orderStatus"),
                    key: "status.name"
                },
                {
                    name: this.$t('pages.ecommerce.order.cols.createdAt'),
                    scopedSlots: {customRender: "created_at"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            selectedRowKeys: [],
            filterParameters: {},
            exportLoading: false
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.order.table;
        },
    },
    mounted(){
        this.filterParameters = {
            sort: 'created_at:desc',
            admin_id: this.userID ? undefined : 0,
            user_id: this.userID
        };

        this.$store.dispatch('ecommerce/order/get', {
            page: {
                current: this.$route.query.page
            },
            filterData: Object.assign(this.$root.getFilterWithUrl({}), this.filterParameters)
        });
    },
    methods: {
        refreshTable(){
            this.$store.dispatch("ecommerce/order/refresh");
        },
        deleteRecord(id){
            this.$store.dispatch("ecommerce/order/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "new":
                    this.$router.push({
                        path: "/ecommerce/order-management/order/save"
                    });
                    break;

                case "refresh":
                    this.refreshTable();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleTableChange(pagination, filterData) {
            this.filterParameters = Object.assign({
                sort: 'created_at:desc',
                admin_id: this.userID ? undefined : 0,
                user_id: this.userID
            }, filterData);

            this.$store.dispatch("ecommerce/order/get", {
                page: pagination,
                filterData: this.filterParameters
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        exportOrder(){
            this.exportLoading = true;
            this.axios({
                url: this.endpoints['ecommerce_order_export'],
                method: 'get',
                responseType: "blob",
                params: this.filterParameters
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();
            }).finally(() => {
                this.exportLoading = false;
            });
        }
    }
}
</script>

<style>
.order-page-header:after {
    content: "";
    clear: both;
    display: table;
}
</style>