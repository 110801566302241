<template>
    <Order :userID="userID" :showUserBackButton="true"></Order>
</template>

<script>
import Order from "@/components/ecommerce/order";
export default {
    name: "index",
    components: {
        Order
    },
    data(){
        return {}
    },
    computed: {
        userID() {
            return this.$route.query.userID;
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.orders"), [this.$t("menu.ecommerceManagement")]);
    },
    methods: {}
}
</script>

<style scoped>

</style>